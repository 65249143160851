/* @font-face {
  font-family: 'MyriadPro';
  src: local('MyriadPro'), url(./fonts/MyriadPro-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Swiss';
  src: local('Swiss'), url(./fonts/swiss.ttf) format('truetype');
} */

:root {
 
  --brand-color-light: #858f37;
  --brand-color-dark: #646e23;
  --brand-accent-color: #ECC500;
  /* --brand-accent-color: #fc751b; */

  --text: #fff;
  --body-bg: var(--brand-color-light);
  --header-bg: var(--brand-color-dark);
  --footer-bg: var(--brand-color-dark);
  --mobile-menu-bg: var(--brand-color-dark);
  --link: #fff;
  --link-hover: var(--brand-accent-color);
  --link-btn: #fff;
  --link-btn-border: #fff;
  --link-btn-icon: var(--brand-accent-color);
  --link-btn-hover: var(--brand-accent-color);


}